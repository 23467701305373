<template>
  <cz-menu-button
    :menu-max-width="350"
    flat
    :color="color"
    :button-title="user.fullName"
    :menuItems="userMenuItems"
    :button-icon-src="mdiCardAccountDetails"
    with-paddings
  />
</template>

<script>
import { CzMenuButton } from '@/components';
import { mdiCardAccountDetails } from '@mdi/js';
export default {
  name: 'UserInfoMenu',
  components: {
    CzMenuButton
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'info'
    }
  },
  computed: {
    userMenuItems() {
      if (!this.user) {
        return [];
      }

      return [
        {
          title: this.$t('auth.fullName'),
          subtitle: this.user.fullName
        },
        {
          title: this.$t('auth.email'),
          subtitle: this.user.email
        }
      ];
    }
  },
  data() {
    return {
      mdiCardAccountDetails
    };
  }
};
</script>

<style></style>
